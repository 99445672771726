import React, { useEffect, useState } from 'react';
import NutritionalService from '../../../../../services/NutritionalService';
import EmptyState from '../../../Admission/Sections/EmptyState';

const NutritionalHistoryView = ({ patientId, handleEditClick }) => {
  const [nutritionalHistory, setNutritionalHistory] = useState({});

  useEffect(() => {
    fetchNutritionalHistory();
  }, []);

  const fetchNutritionalHistory = async () => {
    try {
      const res = await NutritionalService.getDetail(patientId);
      setNutritionalHistory(res);
    } catch (error) {
      console.error(error);
    }
  };

  const renderField = (label, value) => (
    <div className="flex flex-col flex-grow-0">
      <label className="mb-2.5 block text-black dark:text-white">{label}</label>
      <div>{value}</div>
    </div>
  );

  if (!nutritionalHistory.id) {
    return (
      <EmptyState
        handleEditClick={handleEditClick}
        entityName="Nutritional History"
      />
    );
  }

  return (
    <div className="grid gap-9">
      <div className="flex flex-col md:flex-row gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex-grow">
          <div className="pt-5 pl-3 pr-3 pb-5">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-9 p-3">
              {renderField('Breast Feeding', nutritionalHistory.breastFeeding)}
              {/* {renderField(
                "Calories per day",
                nutritionalHistory.caloriesPerDay
              )} */}
            </div>
            <div className="grid grid-cols-1 gap-9 p-3">
              <div className="flex justify-end">
                <button
                  className="rounded bg-meta-5 p-3 ml-3 font-medium text-white"
                  onClick={handleEditClick}
                >
                  Edit Birth Detail
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutritionalHistoryView;
