const fields = [
  {
    label: 'fever',
    name: 'Temperature',
    type: 'select',
    options: ['Fever', 'Hypothermia', 'Normal', 'N/A'],
    required: true,
  },
  {
    label: 'heartRate',
    name: 'Heart Rate',
    type: 'select',
    options: ['Tachycardia', 'Bradycadia', 'Normal'],
    required: true,
  },
  {
    label: 'peripheralPulses',
    name: 'Peripheral pulses',
    type: 'select',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    label: 'peripheralPulsesNo',
    name: 'Peripheral Pulses No',
    type: 'text',
    hide: true,
  },
  {
    label: 'bp',
    name: 'BP',
    type: 'select',
    options: ['Hypertension', 'Hypotension', 'Normal'],
    required: true,
  },
  {
    label: 'rr',
    name: 'Respiratory Rate',
    type: 'select',
    options: ['Tachypnea', 'Bradypnea', 'Normal'],
    required: true,
  },
  {
    label: 'crt',
    name: 'CRT',
    type: 'select',
    options: ['Normal', 'Prolonged'],
    required: true,
  },
  {
    label: 'o2Satuaration',
    name: 'O2 Saturation',
    type: 'select',
    options: ['Low', 'Normal'],
    required: true,
  },
];

let validationRules = {
  fever: { required: true },
  heartRate: { required: true },
  peripheralPulses: { required: true },
  bp: { required: true },
  rr: { required: true },
  crt: { required: true },
  o2Satuaration: { required: true },
};

export default fields;
export { validationRules };
